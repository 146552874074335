import Form, { formCreate } from '../../../../../components/form';
import request from '../../../../../utils/request';
import Supplys from './components/supplys';
import terminalContactInfo from './components/contact_info/contact_info.vue';
import Products from './components/products/products.vue';
import Goods from './components/goods/goods.vue';

formCreate.component('Supplys', Supplys);
formCreate.component('terminalContactInfo', terminalContactInfo);
formCreate.component('Products', Products);
formCreate.component('Goods', Goods);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'terminal_audit_form',
      formParentCode: 'CRM20211102000002755',
      rule: [],
      mdmCustomerContactVos: [
        {
          contactMain: '',
          contactName: '',
          contactPhone: '',
          id: new Date().getTime(),
        },
      ],
      supplys: [
        {
          supplyType: '', // 供货关系类型
          positionCode: '', // 负责人
          orgCode: '', // 组织
          customerCode: '', // 上级客户
          saleCompany: '', // 销售公司
          channel: '', // 渠道
          details: [],
          userName: '1',
          id: new Date().getTime(),
        },
      ],
      mdmCustomerContactVosItem: {
        contactMain: '',
        contactName: '',
        contactPhone: '',
      },
      supplysItem: {
        supplyType: '', // 供货关系类型
        positionCode: '', // 负责人
        orgCode: '', // 组织
        customerCode: '', // 上级客户
        saleCompany: '', // 销售公司
        channel: '', // 渠道
        userName: '',
        details: [],
      },
      showData: [],
      products: [],
      goods: [],
    };
  },
  mounted() {
    this.getTerminalType();
  },
  methods: {
    // 获取
    getTerminalType() {
      request.get('/mdm/mdmdictdata/dictTree', { dictTypeCode: 'sfa_terminal_xi' }).then((res) => {
        if (res.success) {
          this.terminalType = res.result || [];
        }
      });
    },
    init() {
      request.post('/mdm/mdmTerminalExpandController/appletTerminalApproveRecordDetail', {
        id: this.formConfig.row.id,
        formNo: this.formConfig.row.id,
      }).then((res) => {
        if (res.success) {
          const { result } = res;
          this.mdmCustomerContactVos = !result || !result.contacts || result.contacts.length === 0
            ? [
              {
                contactMain: '',
                contactName: '',
                contactPhone: '',
                id: new Date().getTime(),
              },
            ]
            : result.contacts;
          this.supplys = !result || !result.supplys || result.supplys.length === 0
            ? [
              {
                supplyType: '', // 供货关系类型
                positionCode: '', // 负责人
                orgCode: '', // 组织
                customerCode: '', // 上级客户
                saleCompany: '', // 销售公司
                channel: '', // 渠道
                details: [],
                userName: '',
                id: new Date().getTime(),
              },
            ]
            : result.supplys;
          if (result.thisProducts && result.thisProducts.length > 0) {
            result.thisProducts = result.thisProducts.map((v) => {
              const obj = v;
              obj.urls = (obj.imgUrls || '').split(',');
              obj.imgList = ((obj.urls || []).map((p) => ({
                url: p,
              })) || []);
              return obj;
            });
          }
          if (result.competitionProducts && result.competitionProducts.length > 0) {
            result.competitionProducts = result.competitionProducts.map((v) => {
              const obj = v;
              obj.urls = (obj.imgUrls || '').split(',');
              obj.imgList = ((obj.urls || []).map((p) => ({
                url: p,
              })) || []);
              return obj;
            });
          }
          this.products = !result || !result.thisProducts || result.thisProducts.length === 0
            ? []
            : result.thisProducts;
          this.goods = !result || !result.competitionProducts || result.competitionProducts.length === 0
            ? []
            : result.competitionProducts;
          const contacts = this.getRule('contacts');
          const supplys = this.getRule('supplys');
          const products = this.getRule('products');
          const goods = this.getRule('goods');
          contacts.props = {
            ...contacts.props,
            contactInfoData: this.mdmCustomerContactVos,
          };
          supplys.props = {
            ...supplys.props,
            extendInfoData: this.supplys,
          };
          products.props = {
            ...products.props,
            extendInfoData: this.products,
          };
          goods.props = {
            ...supplys.props,
            extendInfoData: this.goods,
          };
          if (res.result.ext41) {
            for (const item of this.terminalType) {
              if (item.dictCode === res.result.ext41) { // 找到了大类
                const dataList = item.children || [];
                const tempArr = [];
                dataList.forEach((t) => {
                  tempArr.push({
                    label: t.dictValue,
                    value: t.dictCode,
                  });
                });
                const ext42 = this.getRule('ext42');
                ext42.options = tempArr;
              }
            }
          }
          this.setValue(result);
        }
      });
    },
    formComplete() {
      // 联系人添加按钮
      const title2 = this.getRule('title2');
      const mdmCustomerContactVos = this.getRule('contacts');
      if (this.formConfig.code !== 'view') {
        title2.props.showAdd = true;
      }
      mdmCustomerContactVos.props = {
        ...mdmCustomerContactVos.props,
        contactInfoData: this.mdmCustomerContactVos,
      };
      title2.on = {
        add: () => {
          this.mdmCustomerContactVos.push({
            ...this.mdmCustomerContactVosItem,
            id: new Date().getTime(),
          });
          mdmCustomerContactVos.props = {
            ...mdmCustomerContactVos.props,
            contactInfoData: this.mdmCustomerContactVos,
          };
        },
      };
      mdmCustomerContactVos.on = {
        del: (index) => {
          this.mdmCustomerContactVos.splice(index, 1);
        },
      };

      // 联系人添加按钮
      const title3 = this.getRule('title3');
      const supplys = this.getRule('supplys');
      if (this.formConfig.code !== 'view') {
        title3.props.showAdd = true;
      }
      supplys.props = {
        ...supplys.props,
        extendInfoData: this.supplys,
      };
      title3.on = {
        add: () => {
          this.supplys.push({
            ...this.supplysItem,
            id: new Date().getTime(),
          });
          supplys.props = {
            ...supplys.props,
            extendInfoData: this.supplys,
          };
        },
      };
      supplys.on = {
        del: (index) => {
          this.supplys.splice(index, 1);
        },
      };
      // 联系人添加按钮
      const title5 = this.getRule('title5');
      const products = this.getRule('products');
      if (this.formConfig.code !== 'view') {
        title5.props.showAdd = true;
      }
      products.props = {
        ...products.props,
        extendInfoData: this.products,
      };
      title5.on = {
        add: () => {
          this.products.push({
            ...this.productsItem,
            id: new Date().getTime(),
          });
          products.props = {
            ...products.props,
            extendInfoData: this.products,
          };
        },
      };
      products.on = {
        del: (index) => {
          this.products.splice(index, 1);
        },
      };
      // 联系人添加按钮
      const title6 = this.getRule('title6');
      const goods = this.getRule('goods');
      if (this.formConfig.code !== 'view') {
        title6.props.showAdd = true;
      }
      goods.props = {
        ...goods.props,
        extendInfoData: this.goods,
      };
      title6.on = {
        add: () => {
          this.goods.push({
            ...this.goodsItem,
            id: new Date().getTime(),
          });
          goods.props = {
            ...goods.props,
            extendInfoData: this.goods,
          };
        },
      };
      goods.on = {
        del: (index) => {
          this.goods.splice(index, 1);
        },
      };
      if (this.formConfig.row && this.formConfig.row.id) {
        this.init();
      }
    },

  },
};
