<script>
import TablePage from '../../../../../../../components/table_page';
import configs from './config';

export default {
  extends: TablePage,
  props: {
    data: Array,
    dataType: String,
    selectKeys: Array,
  },
  data() {
    return {
      requestUrl: '/mdm/baseTreeController/productLevelTree',
      configs: {
        ...configs,
        pagination: false,
      },
      checkStrictly: true,
    };
  },
  created() {
    // this.init();
  },
  beforeMount() {
  },
  methods: {
    afterCheckboxChange() {
      this.$emit('onSelect', this.selectRow);
    },
    afterCheckAllChange(val) {
      this.$emit('onSelect', val.selection);
    },
    async init() {
      await this.getConfigList('terminal_product_level', true, true);
    },
    afterGetList() {
      this.selection = this.selectKeys || [];
    },
  },

};
</script>

<style>

</style>
