<template>
  <div>
    <div class="supplys-title">
      竞品信息
      <i
        v-if="!disabled"
        class="el-icon-circle-plus-outline addBtn"
        circle
        @click="() => this.addSupply()"
      ></i>
    </div>
    <div
      v-for="(item, index) in dataList"
      class="terminalInfo-contant-box"
      :key="index"
    >
      <div class="terminalInfo-contant">
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">产品名称</span>
          <el-input
            v-model="item.productName"
            placeholder="请输入产品名称"
            style="width: 100%"
            @input="(value) => onChange('productName', index, value)"
            :disabled="disabled"
          ></el-input>
        </div>
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">陈列面</span>
          <el-input-number
            v-model="item.displayNum"
            placeholder="请输入陈列面"
            style="width: 100%"
            @input="(value) => onChange('displayNum', index, value)"
            :disabled="disabled"
            :controls="false"
            :precision='0'
          ></el-input-number>
        </div>
      </div>
      <div class="terminalInfo-contant">
        <div class="terminalInfo-contact-item file">
          <span class="terminalInfo-contact-item-title">照片</span>
          <el-upload
            :headers="headerObj"
            action="/upload/uploadController/uploadOssList"
            :with-credentials="true"
            list-type="picture-card"
            :auto-upload="true"
            :on-preview="handlePictureCardPreview"
            :on-remove="(res) => handleRemove(res, index)"
            :on-success="(res) => handleSuccess(res, index)"
            :limit="10"
            :file-list="item.imgList"
            accept=".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF"
            :disabled="disabled"
          >
            <i slot="default" class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" :modal="false">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </div>
      </div>
      <div class="terminalInfo-contant-delete">
        <i
          v-if="!disabled"
          class="el-icon-delete"
          @click="() => deleteSupply(index)"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
import Storage from '@/found/utils/storage';

export default {
  components: {},
  props: {
    extendInfoData: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  name: 'Supplys',
  data() {
    return {
      dataList: [],
      dialogVisible: false,
      operateItemIndex: 0,
      index: '',
      dialogImageUrl: '',
      headerObj: {
        loginUserToken: Storage.l.get('TokenKey'),
      },
    };
  },
  watch: {
    extendInfoData() {
      this.dataList = this.extendInfoData;
    },
  },

  created() {},
  methods: {
    handleRemove(file, index) {
      this.dataList[index].imgList.splice(
        this.dataList[index].imgList.indexOf(file.url),
      );
      this.$emit('input', this.dataList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleSuccess(res, index) {
      if (res.code === 200 && res.success) {
        res.result.forEach((v) => {
          this.dataList[index].imgList.push({
            fileName: v.fileName,
            pictureUrl: v.url,
            url: v.url,
            urlPath: v.urlPath,
            urlPathPrefix: v.urlPathPrefix,
            objectName: v.objectName,
          });
        });
        this.$emit('input', this.dataList);
      }
    },
    // 新增供货关系
    addSupply() {
      this.dataList.push({
        productName: '',
        displayNum: '',
        imgList: [],
      });
      this.$emit('input', this.dataList);
    },
    // 删除供货关系
    deleteSupply(index) {
      this.dataList.splice(index, 1);
      if (this.dataList.length === 0) {
        this.$emit('input', []);
      } else {
        this.$emit('input', this.dataList);
      }
    },
    onChange(type, index, value) {
      if (type === 'displayNum') {
        this.dataList[index].displayNum = value;
      } else if (type === 'productName') {
        this.dataList[index].productName = value;
      }
      this.$emit('input', this.dataList);
    },
  },
};
</script>

<style lang = "less" scoped>
/deep/.el-button--small.is-circle {
  padding: 5px;
  margin-right: 8px;
}
/deep/.terminalInfo-contact-item-title {
  padding: 0px 10px;
  text-align: right;
}
.supplys-title {
  /* border-top: 1px solid #aeaeae; */
  /* padding-top: 0.5rem; */
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  .addBtn {
    cursor: pointer;
    color: #409eff;
    margin-left: 5px;
    font-size: 16px;
  }
}
.terminalInfo-contant-box {
  margin-bottom: 19px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  .terminalInfo-contant {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    .terminalInfo-contact-item {
      /* flex:1; */
      display: flex;
      width: 33.33%;
      margin-bottom: 18px;
      .terminalInfo-contact-item-title {
        width: 125px;
        color: #606266;
      }
    }
  }
  .terminalInfo-contant-delete {
    width: 5%;
    i {
      color: #409eff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .terminalInfo-supplys-btn-group {
    width: 100%;
    .terminalInfo-supplys-btn {
      color: #1c92ff;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
}
.cost-select {
  position: relative;

  /deep/ .el-input__inner {
    padding-right: 30px;
  }

  .clear-icon {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 5px;
    top: 0;
    text-align: center;
    color: #c0c4cc;
    transition: all 0.3s;
    padding-top: 1px;
    display: none;

    .el-icon-circle-close {
      width: 100%;
      font-size: 14px;
      cursor: pointer;
      transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }

  &:hover {
    .clear-icon {
      display: block;
    }
  }
}
.file {
  width: 100% !important;
}
</style>
