<template>
  <div>

    <div class="supplys-title">供货关系
      <i v-if="!disabled" class="el-icon-circle-plus-outline addBtn" circle @click="()=>this.addSupply()"></i>
    </div>
    <div v-for="(item,index) in dataList" class="terminalInfo-contant-box" :key="index">
      <div class="terminalInfo-contant">
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">供货人</span>
          <el-select
            :disabled='disabled'
            filterable
            remote
            v-model="item.positionCode"
            @focus="()=>getPositionList(index)"
            :remote-method="searchPositionList"
            @change="(value)=>onChange('positionList',index,value,item.positionList)"
            placeholder="请选择"
            style="width: 100%;">
            <el-option v-for="position in item.positionList" :key="position.positionCode" :label="position.unionName" :value="position.positionCode">
            </el-option>
          </el-select>
        </div>
        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">供货组织</span>
          <TreeSelect @input="(value)=>onChange('orgCode',index,value)" :disabled='true' placeholder="请选择" :options="orgList" :props="treeProps" :value='item.orgCode' style="width: 100%;" />
        </div>

        <div class="terminalInfo-contact-item">
          <span class="terminalInfo-contact-item-title">上级客户</span>
          <el-select :disabled='disabled' v-model="item.customerCode" filterable remote @change="()=>onChange()" placeholder="请选择" style="width: 100%;" :remote-method="remoteMethod1">
            <el-option v-for="item in customerList2" :key="item.customerCode" :label="item.unionName" :value="item.customerCode">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="terminalInfo-contant-delete">
        <i v-if="!disabled && index>0" class="el-icon-delete" @click="()=>deleteSupply(index)"></i>
      </div>
      <div class="terminalInfo-supplys-btn-group">
        <span v-if="!disabled" class="terminalInfo-supplys-btn">
          <el-button icon="el-icon-plus" type="text" @click="()=>openModal('Shopping',index)">添加商品</el-button>
        </span>
        <TableList :disabled="disabled" style="marginTop: 10px" :data="item.details" @deleteItem="(rowIndex)=>deleteItem(rowIndex)" />
      </div>
    </div>
    <a-modal v-if="dialogVisible" width="800px" :visible="dialogVisible" :title="title" @cancel="closeModal">
      <component :is="formName" :selectKeys="selectKeys" @onSelect="(value)=>onSelect(value)" />
      <div slot="footer">
        <el-button type="primary" @click="()=>submitData()">确定</el-button>
        <el-button @click="()=>closeModal()">取消</el-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Shopping from './shopping/shopping.vue';
import TableList from './table/table.vue';
import ProductLevel from './productLevel/productLevel.vue';
import request from '../../../../../../utils/request';
import TreeSelect from '../../../../../../components/form/components/form_select_tree.vue';

export default {
  components: {
    Shopping,
    TableList,
    ProductLevel,
    TreeSelect,
  },
  props: {
    value: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  name: 'Supplys',
  data() {
    return {
      dataList: [
        {
          supplyType: 'default',
          customerCode: '',
          dataType: '',
          orgCode: '',
          positionCode: '',
          details: [],
          positionList: [],
        },
      ],
      dialogVisible: false,
      operateItemIndex: 0,
      title: '',
      formName: '',
      orgList: [],
      saleCompanyList: [], // 销售公司下拉
      channelList: [], // 渠道下拉
      customerList: [], // 客户用户
      customerList2: [], // 上级用户
      selectItem: [],
      selectKeys: [],
      treeProps: {
        parent: 'parentCode', // 父级唯一标识
        value: 'code', // 唯一标识
        label: 'name', // 标签显示
        children: 'children', // 子级
      },
      supplyTypeOptions: [], // 供货关系类型
      loading: false,
      index: '',
    };
  },
  watch: {
    value() {
      this.dataList = this.value;
      this.dataList.forEach((item, index) => {
        if (!Array.isArray(this.dataList[index].positionList)) {
          const positionList = [
            {
              positionCode: this.dataList[index].positionCode,
              unionName: `${this.dataList[index].orgName}/${
                this.dataList[index].positionName
              }/${this.dataList[index].fullName}`,
            },
          ];
          this.$set(this.dataList[index], 'positionList', positionList);
          this.dataList[index].positionCode = item.positionCode;
        }
      });
    },
  },

  created() {
    request.post('/mdm/baseTreeController/orgTree', {}, { headers: { functionCode: 'select-org' } }).then((res) => {
      if (res.success) {
        this.orgList = res.result ? res.result : [];
      }
    });
    request.post('/mdm/mdmCustomerMsgController/select', {
      pageSize: 5000,
    }).then((res) => {
      if (res.success) {
        this.customerList2 = res.result ? res.result : [];
      }
    });
    // request
    //   .post('/mdm/mdmCustomerUserController/findCustomerUserSelectList')
    //   .then((res) => {
    //     if (res.success) {
    //       this.customerList = res.result ? res.result : [];
    //     }
    //   });
    // request
    //   .post('/mdm/mdmdictdata/list', {
    //     dictTypeCode: 'channel',
    //   })
    //   .then((res) => {
    //     this.channelList = res.result ? res.result : [];
    //   });
    // request
    //   .post('/mdm/mdmdictdata/list', {
    //     dictTypeCode: 'sale_company',
    //   })
    //   .then((res) => {
    //     this.saleCompanyList = res.result ? res.result : [];
    //   });
    // request
    //   .post('/mdm/mdmdictdata/list', {
    //     dictTypeCode: 'terminal_supply_type',
    //   })
    //   .then((res) => {
    //     this.supplyTypeOptions = res.result ? res.result : [];
    //   });
  },
  methods: {

    // 新增供货关系
    addSupply() {
      this.dataList.push({
        supplyType: 'default',
        customerCode: '',
        orgCode: '',
        positionCode: '',
        details: [],
        positionList: [],
      });
      this.$emit('input', this.dataList);
    },

    // 模糊搜索上级客户
    remoteMethod1(query) {
      this.loading = true;
      request
        .post('/mdm/mdmCustomerMsgController/select', { customerName: query })
        .then((res) => {
          if (res.success) {
            this.loading = false;
            this.customerList2 = res.result ? res.result : [];
          }
        });
    },
    // remoteMethod2(query) {
    //   this.loading = true;
    //   request
    //     .post('/mdm/mdmCustomerUserController/findCustomerUserSelectList', { fullName: query })
    //     .then((res) => {
    //       if (res.success) {
    //         console.log(res);
    //         this.loading = false;
    //         this.customerList = res.result ? res.result : [];
    //       }
    //     });
    // },

    // 删除供货关系
    deleteSupply(index) {
      this.dataList.splice(index, 1);
      if (this.dataList.length === 0) {
        this.dataList.push({
          supplyType: 'default',
          customerCode: '',
          orgCode: '',
          positionCode: '',
          details: [],
          positionList: [],
        });
        this.$emit('input', []);
      } else {
        this.$emit('input', this.dataList);
      }
    },

    openModal(type, index) {
      if (type === 'Shopping') {
        this.title = '选择商品';
      } else {
        this.title = '选中产品层级';
      }
      this.selectKeys = this.dataList[index].details
        && this.dataList[index].details.map(
          (item) => (item.code ? item.code : item.productCode),
        );
      this.operateItemIndex = index;
      this.formName = type;
      this.dialogVisible = true;
    },
    closeModal() {
      this.dialogVisible = false;
    },
    onSelect(value) {
      this.selectItem = value;
    },
    submitData() {
      const index = this.operateItemIndex;
      let { selectItem } = this;
      const { formName } = this;
      if (formName === 'Shopping') {
        this.dataList[index].dataType = '1';
        selectItem = selectItem.map((item) => ({
          ...item,
          code: item.productCode,
          name: item.productName,
          dataType: '1',
        }));
      } else {
        this.dataList[index].dataType = '2';
        selectItem = selectItem.map((item) => ({
          ...item,
          dataType: '2',
        }));
      }
      // console.log(selectItem);
      // console.log(this.dataList[index].details);
      selectItem = [...selectItem, ...this.dataList[index].details];
      this.$set(this.dataList[index], 'details', selectItem);
      // this.dataList[index]
      this.dialogVisible = false;
      this.$emit('input', this.dataList);
    },
    // 获取职位信息
    getPositionList(index) {
      this.index = index;
      request
        .post(
          '/mdm/mdmPositionController/positionSelectList',
          {},
          { headers: { functionCode: 'select-position' } },
        )
        .then((res) => {
          if (res.success) {
            this.$set(this.dataList[index], 'positionList', res.result);
          }
        });
    },
    // 搜索职位
    searchPositionList(val) {
      request
        .post(
          '/mdm/mdmPositionController/positionSelectList',
          {
            unionName: val,
          },
          { headers: { functionCode: 'select-position' } },
        )
        .then((res) => {
          if (res.success) {
            this.$set(this.dataList[this.index], 'positionList', res.result);
          }
        });
    },
    onChange(type, index, value, positionList) {
      if (type === 'positionList') {
        const data = positionList.find((item) => item.positionCode === value);
        this.dataList[index].orgCode = data.orgCode;
      }
      if (type === 'orgCode' && this.dataList[index].orgCode !== value) {
        this.dataList[index].orgCode = value;
        this.dataList[index].positionCode = '';
      }
      this.$emit('input', this.dataList);
    },
    deleteItem(rowIndex) {
      const index = this.operateItemIndex;
      this.dataList[index].details.splice(rowIndex, 1);
      this.$emit('input', this.dataList);
    },
    getSupplyTypeOptions() {
      request
        .post('/mdm/mdmdictdata/list', {
          dictTypeCode: 'terminal_supply_type',
        })
        .then((res) => {
          this.supplyTypeOptions = res.result ? res.result : [];
        });
    },
    // 改变供货类型
    changeSupplyType(index, value) {
      console.log(index, value);
    },
  },
};
</script>

<style lang = "less" scoped>
/deep/.el-button--small.is-circle {
  padding: 5px;
  margin-right: 8px;
}
/deep/.terminalInfo-contact-item-title {
  padding: 0px 10px;
  text-align: right;
}
.supplys-title {
  /* border-top: 1px solid #aeaeae; */
  /* padding-top: 0.5rem; */
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 600;
  .addBtn {
    cursor: pointer;
    color: #409eff;
    margin-left: 5px;
    font-size: 16px;
  }
}
.terminalInfo-contant-box {
  margin-bottom: 19px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 2.5rem;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
  .terminalInfo-contant {
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    .terminalInfo-contact-item {
      /* flex:1; */
      display: flex;
      width: 33.33%;
      margin-bottom: 18px;
      .terminalInfo-contact-item-title {
        width: 125px;
        color: #606266;
      }
    }
  }
  .terminalInfo-contant-delete {
    width: 5%;
    i {
      color: #409eff;
      margin-left: 20px;
      cursor: pointer;
    }
  }
  .terminalInfo-supplys-btn-group {
    width: 100%;
    .terminalInfo-supplys-btn {
      color: #1c92ff;
      &:first-child {
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
