var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "supplys-title" }, [
        _vm._v("供货关系 "),
        !_vm.disabled
          ? _c("i", {
              staticClass: "el-icon-circle-plus-outline addBtn",
              attrs: { circle: "" },
              on: {
                click: function () {
                  return this$1.addSupply()
                },
              },
            })
          : _vm._e(),
      ]),
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "terminalInfo-contant-box" },
          [
            _c("div", { staticClass: "terminalInfo-contant" }, [
              _c(
                "div",
                { staticClass: "terminalInfo-contact-item" },
                [
                  _c(
                    "span",
                    { staticClass: "terminalInfo-contact-item-title" },
                    [_vm._v("供货人")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.disabled,
                        filterable: "",
                        remote: "",
                        "remote-method": _vm.searchPositionList,
                        placeholder: "请选择",
                      },
                      on: {
                        focus: function () {
                          return _vm.getPositionList(index)
                        },
                        change: function (value) {
                          return _vm.onChange(
                            "positionList",
                            index,
                            value,
                            item.positionList
                          )
                        },
                      },
                      model: {
                        value: item.positionCode,
                        callback: function ($$v) {
                          _vm.$set(item, "positionCode", $$v)
                        },
                        expression: "item.positionCode",
                      },
                    },
                    _vm._l(item.positionList, function (position) {
                      return _c("el-option", {
                        key: position.positionCode,
                        attrs: {
                          label: position.unionName,
                          value: position.positionCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "terminalInfo-contact-item" },
                [
                  _c(
                    "span",
                    { staticClass: "terminalInfo-contact-item-title" },
                    [_vm._v("供货组织")]
                  ),
                  _c("TreeSelect", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      disabled: true,
                      placeholder: "请选择",
                      options: _vm.orgList,
                      props: _vm.treeProps,
                      value: item.orgCode,
                    },
                    on: {
                      input: function (value) {
                        return _vm.onChange("orgCode", index, value)
                      },
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "terminalInfo-contact-item" },
                [
                  _c(
                    "span",
                    { staticClass: "terminalInfo-contact-item-title" },
                    [_vm._v("上级客户")]
                  ),
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled: _vm.disabled,
                        filterable: "",
                        remote: "",
                        placeholder: "请选择",
                        "remote-method": _vm.remoteMethod1,
                      },
                      on: {
                        change: function () {
                          return _vm.onChange()
                        },
                      },
                      model: {
                        value: item.customerCode,
                        callback: function ($$v) {
                          _vm.$set(item, "customerCode", $$v)
                        },
                        expression: "item.customerCode",
                      },
                    },
                    _vm._l(_vm.customerList2, function (item) {
                      return _c("el-option", {
                        key: item.customerCode,
                        attrs: {
                          label: item.unionName,
                          value: item.customerCode,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "terminalInfo-contant-delete" }, [
              !_vm.disabled && index > 0
                ? _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function () {
                        return _vm.deleteSupply(index)
                      },
                    },
                  })
                : _vm._e(),
            ]),
            _c(
              "div",
              { staticClass: "terminalInfo-supplys-btn-group" },
              [
                !_vm.disabled
                  ? _c(
                      "span",
                      { staticClass: "terminalInfo-supplys-btn" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { icon: "el-icon-plus", type: "text" },
                            on: {
                              click: function () {
                                return _vm.openModal("Shopping", index)
                              },
                            },
                          },
                          [_vm._v("添加商品")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("TableList", {
                  staticStyle: { marginTop: "10px" },
                  attrs: { disabled: _vm.disabled, data: item.details },
                  on: {
                    deleteItem: function (rowIndex) {
                      return _vm.deleteItem(rowIndex)
                    },
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      _vm.dialogVisible
        ? _c(
            "a-modal",
            {
              attrs: {
                width: "800px",
                visible: _vm.dialogVisible,
                title: _vm.title,
              },
              on: { cancel: _vm.closeModal },
            },
            [
              _c(_vm.formName, {
                tag: "component",
                attrs: { selectKeys: _vm.selectKeys },
                on: {
                  onSelect: function (value) {
                    return _vm.onSelect(value)
                  },
                },
              }),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function () {
                          return _vm.submitData()
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function () {
                          return _vm.closeModal()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }