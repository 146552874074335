<template>
  <div>
    <div class="sale-area-container" v-for="(item,index) in contactInfoData" :key="item.id">
    <ContactInfoItem :fieldDisabled="disabled" :ref="item.id" class="sale-area-item" :contactInfoData="item" :index="index"/>
    <el-button v-if="!disabled && index>0" class="del-btn" type="text" @click="del(index)" icon="el-icon-delete"></el-button>
    </div>
  </div>
</template>

<script>
import ContactInfoItem from './contact_info_item.vue';

export default {
  components: {
    ContactInfoItem,
  },
  props: {
    contactInfoData: Array,
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    del(index) {
      this.$emit('del', index);
    },
    getFormData() {
      const data = [];
      this.contactInfoData.forEach((v) => {
        if (this.$refs[v.id]) {
          data.push(this.$refs[v.id][0].getFormData());
        }
      });
      return data;
    },
  },
};
</script>

<style lang="less" scoped>
  .sale-area-container{
    display: flex;
    align-items: top;
    .sale-area-item{
      flex: 1;
      position: relative;
      top: 10px;
    }
    .del-btn{
      width: 50px;
      color: red;
    }
  }
</style>
