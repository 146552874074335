var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("SelectConfig", {
        ref: "selectConfig",
        on: { onGetSelect: _vm.onGetSelect },
      }),
      _c("div", { staticClass: "supplys-title" }, [
        _vm._v(" 本品信息 "),
        !_vm.disabled
          ? _c("i", {
              staticClass: "el-icon-circle-plus-outline addBtn",
              attrs: { circle: "" },
              on: {
                click: function () {
                  return this$1.addSupply()
                },
              },
            })
          : _vm._e(),
      ]),
      _vm._l(_vm.dataList, function (item, index) {
        return _c(
          "div",
          { key: index, staticClass: "terminalInfo-contant-box" },
          [
            _c("div", { staticClass: "terminalInfo-contant" }, [
              _c("div", { staticClass: "terminalInfo-contact-item" }, [
                _c("span", { staticClass: "terminalInfo-contact-item-title" }, [
                  _vm._v("产品名称"),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "cost-select",
                    on: {
                      click: function ($event) {
                        return _vm.openModal(index)
                      },
                    },
                  },
                  [
                    _c("el-input", {
                      attrs: { readonly: true, placeholder: "产品名称" },
                      model: {
                        value: item.productName,
                        callback: function ($$v) {
                          _vm.$set(item, "productName", $$v)
                        },
                        expression: "item.productName",
                      },
                    }),
                    !_vm.disabled
                      ? _c("div", { staticClass: "clear-icon" }, [
                          _c("i", {
                            staticClass: "el-icon-circle-close",
                            on: {
                              click: function ($event) {
                                return _vm.clearFn(
                                  $event,
                                  "product",
                                  _vm.k,
                                  _vm.j
                                )
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
              _c(
                "div",
                { staticClass: "terminalInfo-contact-item" },
                [
                  _c(
                    "span",
                    { staticClass: "terminalInfo-contact-item-title" },
                    [_vm._v("陈列面")]
                  ),
                  _c("el-input-number", {
                    staticStyle: { width: "100%" },
                    attrs: {
                      placeholder: "请输入陈列面",
                      disabled: _vm.disabled,
                      controls: false,
                      precision: 0,
                    },
                    on: {
                      input: function (value) {
                        return _vm.onChange("displayNum", index, value)
                      },
                    },
                    model: {
                      value: item.displayNum,
                      callback: function ($$v) {
                        _vm.$set(item, "displayNum", $$v)
                      },
                      expression: "item.displayNum",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "terminalInfo-contant" }, [
              _c(
                "div",
                { staticClass: "terminalInfo-contact-item file" },
                [
                  _c(
                    "span",
                    { staticClass: "terminalInfo-contact-item-title" },
                    [_vm._v("照片")]
                  ),
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        headers: _vm.headerObj,
                        action: "/upload/uploadController/uploadOssList",
                        "with-credentials": true,
                        "list-type": "picture-card",
                        "auto-upload": true,
                        "on-preview": _vm.handlePictureCardPreview,
                        "on-remove": function (res) {
                          return _vm.handleRemove(res, index)
                        },
                        "on-success": function (res) {
                          return _vm.handleSuccess(res, index)
                        },
                        limit: 10,
                        "file-list": item.imgList,
                        accept: ".jpg,.jpeg,.png,.gif,.JPG,.JPEG,.PNG,.GIF",
                        disabled: _vm.disabled,
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "el-icon-plus",
                        attrs: { slot: "default" },
                        slot: "default",
                      }),
                    ]
                  ),
                  _c(
                    "el-dialog",
                    {
                      attrs: { visible: _vm.dialogVisible, modal: false },
                      on: {
                        "update:visible": function ($event) {
                          _vm.dialogVisible = $event
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          width: "100%",
                          src: _vm.dialogImageUrl,
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "terminalInfo-contant-delete" }, [
              !_vm.disabled
                ? _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function () {
                        return _vm.deleteSupply(index)
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }