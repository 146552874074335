<template>

  <div class="custom-table">
    <vxe-table border show-overflow ref="xTable" :data="data">
      <vxe-table-column field="sort" width="120" align="center" title="类型">
        <template v-slot="{ row }">
            {{row.dataType === '1'?'商品' : '产品层级'}}
        </template>
      </vxe-table-column>
      <vxe-table-column field="code" align="center" title="编码">
      </vxe-table-column>
      <vxe-table-column field="name" align="center" title="名称">
      </vxe-table-column>
      <vxe-table-column v-if="!disabled" field="operate-btn" align="center" width="60"  title="操作">
        <template v-slot="{ row,rowIndex}">
          <div class="setting-btn">
            <Popconfirm
              title="确定删除该数据？"
              confirm-button-type="text"
              @confirm="deleteItem(rowIndex)"
            >
              <el-button slot="reference" type="text">
                <i class="el-icon-delete" style="color: #f5222d"></i>
              </el-button>
            </Popconfirm>
          </div>
        </template>
      </vxe-table-column>
    </vxe-table>
  </div>

</template>

<script>
import { Popconfirm } from 'element-ui';

export default {
  props: {
    data: Array,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Popconfirm,
  },
  data() {
    return {
      tableData: [],
    };
  },
  watch: {
    data(newVal, oldVal) {
      const tempData = newVal;
      tempData.forEach((item, index) => {
        item.ext9 = item.productLevelCode || item.ext9;
        item.ext10 = item.productLevelName || item.ext10;
      });
      this.tableData = tempData;
    },
  },
  methods: {
    deleteItem(rowIndex) {
      this.$emit('deleteItem', rowIndex);
    },
  },

};
</script>

<style>

</style>
